import Joi from 'joi';

export type FormErrorState = {
  errors: {
    [key: string]: Joi.ValidationErrorItem | null | undefined;
  };
};

export const validateFields = <T>(
  schema: Joi.Schema,
  getFormData: () => T,
  abortEarly = true,
): { error?: Joi.ValidationError; value: T } => {
  const { error, value } = schema.validate(getFormData(), {
    abortEarly,
  });
  return { error, value };
};

export const parseErrors = (
  errors: Joi.ValidationError,
): { [key: string]: Joi.ValidationErrorItem } =>
  errors.details.reduce(
    (acc, error) => ({
      ...acc,
      [error.path[0]]: error,
    }),
    {},
  );

export const fieldIsValid = (validation: FormErrorState) => (
  fieldName: string,
): boolean => !validation.errors[fieldName];

export const clearValidationErrors = (validation: FormErrorState) => (
  fieldName: string,
): void => {
  validation.errors[fieldName] = null;
};
