
/* eslint-disable no-undef */
import {
  IonInput,
  IonItem,
  IonTextarea,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
  IonSelect,
  IonSelectOption,
} from '@ionic/vue';
import {
  alertCircle,
  locateOutline,
  eyeOffOutline,
  eyeOutline,
} from 'ionicons/icons';
import {
  defineComponent,
  toRefs,
  ref,
  onMounted,
  PropType,
  watch,
} from 'vue';
import Joi from 'joi';
import { validateFields } from '@/lib/forms';

export default defineComponent({
  components: {
    IonInput,
    IonItem,
    IonRow,
    IonCol,
    IonTextarea,
    IonIcon,
    IonButton,
    IonSelect,
    IonSelectOption,
  },
  props: {
    label: String,
    className: String,
    modelValue: [String, Number],
    value: String,
    type: {
      type: String,
      default: 'text',
    },
    inputmode: String,
    minlength: String,
    placeholder: String,
    disabled: Boolean,
    validation: Object as PropType<Joi.Schema>,
    name: {
      type: String,
      default: 'field',
    },
    customValidation: Function,
    isAddress: Boolean,
    isTextArea: Boolean,
    isFlat: Boolean,
    showLocationPointer: Boolean,
    hasError: {
      type: Boolean,
      default: false,
    },
    withTooltip: Boolean,
    tooltipContent: String,
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const errorMessage = ref('');
    const inputRef = ref<any>(null);
    const isTooltipOpen = ref(false);
    const showPassword = ref(false);
    const {
      validation,
      name,
      customValidation,
      isAddress,
      hasError,
      modelValue,
      value,
    } = toRefs(props);

    const toggleTooltip = async () =>
      (isTooltipOpen.value = !isTooltipOpen.value);

    const onBlur = () => {
      errorMessage.value = '';
      if (validation.value !== undefined) {
        const schema = Joi.object({
          [name.value]: validation.value,
        });

        const { error } = validateFields(
          schema,
          () => ({
            [name.value as string]: modelValue.value || value.value,
          }),
          false,
        );

        if (error) {
          errorMessage.value = error.message;
        }

        if (customValidation.value) {
          errorMessage.value = customValidation?.value();
        }
      }
    };

    const updateModelValue = (evt: any) => {
      errorMessage.value = '';
      emit('update:modelValue', evt.target.value.trim());
    };

    watch(hasError, (hasError, prevHasError) => {
      if (hasError && hasError !== prevHasError) {
        onBlur();
      }
    });

    if (isAddress.value === true) {
      const selectedPlace = ref<google.maps.places.PlaceResult | null>(
        null,
      );

      const CENTER_LAGOS = { lat: 6.5244, lng: -3.3792 };

      const initAutocomplete = (reference: HTMLInputElement) => {
        const defaultBounds = {
          north: CENTER_LAGOS.lat + 0.1,
          south: CENTER_LAGOS.lat - 0.1,
          east: CENTER_LAGOS.lng + 0.1,
          west: CENTER_LAGOS.lng - 0.1,
        };

        const options = {
          bounds: defaultBounds,
          componentRestrictions: { country: 'ng' },
          fields: [
            'address_components',
            'geometry',
            'icon',
            'name',
            'formatted_address',
            'place_id',
          ],
          origin: CENTER_LAGOS,
          strictBounds: false,
        };

        const autocomplete: google.maps.places.Autocomplete = new google.maps.places.Autocomplete(
          reference,
          options,
        );

        autocomplete.addListener('place_changed', () => {
          selectedPlace.value = autocomplete.getPlace();
          emit(
            'update:modelValue',
            selectedPlace.value.formatted_address,
          );
          emit('change', selectedPlace.value);
          return;
        });
      };

      onMounted(async () => {
        const inputReference = inputRef.value;
        if (inputReference) {
          const inputElement = await ((inputReference as any)
            .$el as HTMLIonInputElement).getInputElement();
          initAutocomplete(inputElement);
        }
      });
    }

    return {
      errorMessage,
      toggleTooltip,
      onBlur,
      updateModelValue,
      isTooltipOpen,
      inputRef,
      alertCircle,
      locateOutline,
      showPassword,
      eyeOffOutline,
      eyeOutline,
    };
  },
});
